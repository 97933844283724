const PathConstants = {
  HOME: '/',
  PAYMENTTYPE: '/payment-type',
  SEVISFEE: '/sevis-fee',
  SEVISFEEFORM: '/sevis-fee/form',
  SEVISFEEPAYMENTMETHOD: '/sevis-fee/payment',
  TRACKPAYMENT: '/track-payment',
  CONSULTATION: '/consultation',
  BOOKCONSULTATION: '/book-consultation',
};

export default PathConstants;
