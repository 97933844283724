import React from 'react';
import { Link } from 'react-router-dom';
import PathConstants from '../../routes/pathConstants';

const NotFound = () => {
  return (
    <>
      <div className='container flex flex-col items-center justify-center min-h-screen text-center'>
        <h3 className='text-[#151A1D]'>Page Error</h3>
        <p className='text-[#939697] w-96 my-6'>
          The page you’re looking for might have been removed, or is temporarily
          unavailable
        </p>
        <div className='flex items-center gap-6'>
          <Link to={PathConstants.CONTACT}>
            <button className='border border-[#17324F] rounded-md text-[#17324F] py-2.5 px-8'>
              Contact Us
            </button>
          </Link>
          <Link to={PathConstants.HOME}>
            <button className='bg-[#17324F] text-white rounded-md py-3 px-8'>
              Back to Home
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
