import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/svgs/logo-educart-new.svg';
import { ReactComponent as Adds } from '../assets/svgs/arrow-right-adds.svg';

import PathConstants from '../routes/pathConstants';

const links = [
  {
    name: 'Home',
  },
  {
    name: 'Payments',
  },
  {
    name: 'How it works',
  },
  {
    name: 'Download',
  },
  {
    name: 'Blog',
  },
  {
    name: 'Contact Us',
  },
];

function Navbar() {
  const [navOpen, setNavOpen] = useState(false);

  const navOpenHandler = () => {
    setNavOpen(true);
    document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
  };

  const navCloseHandler = () => {
    setNavOpen(false);
    document.body.classList.remove('overflow-hidden', 'lg:overflow-auto');
  };

  return (
    <>
      <div
        className={`sticky top-0 bg-white transition-transform duration-500 z-30 shadow-md`}
      >
        <div className='bg-[#20466F] p-2'>
          <div className='container flex flex-row items-center justify-center gap-3 font-raleway'>
            <h3 className='text-[#FBF2E6] md:text-[14px] text-[12px]'>
              Get Free consultation{' '}
            </h3>
            <Link to={PathConstants.CONSULTATION}>
              <h3 className='flex gap-1 underline-offset-2 text-[#EEC58A] md:text-[16px] text-[13px] underline font-bold'>
                Schedule Consultation <Adds />
              </h3>
            </Link>
          </div>
        </div>
        <div className='container '>
          <div className='flex items-center justify-between py-4 md:py-5'>
            <Link to='/'>
              <Logo />
            </Link>
            <div className='items-center hidden lg:flex'>
              {links.map((link, i) => (
                <ScrollLink
                  smooth={true}
                  offset={-100}
                  duration={500}
                  className={`flex items-center cursor-pointer gap-2 py-2 px-4 rounded-full transition-colors hover:bg-[#DDE2FF]`}
                  to={link.name}
                  key={i}
                >
                  <span className='text-sm font-semibold text-[#20466F] font-raleway'>
                    {link.name}
                  </span>
                </ScrollLink>
              ))}
            </div>
            <div className='space-x-5 font-medium'>
              <a
                href='https://educarts-webapp.vercel.app/login'
                target='_blank'
                className='hidden lg:inline-block text-[#17324F] text-[16px]'
              >
                <button>Log In</button>
              </a>
              <a
                href='https://educarts-webapp.vercel.app/login'
                target='_blank'
                className='hidden lg:inline-block border border-[#17324F] text-[16px] text-[#20466F] py-2 px-8 rounded-md'
              >
                <button>Sign Up</button>
              </a>
            </div>

            <button
              type='button'
              className={`lg:hidden transition-opacity ${
                navOpen ? 'opacity-0' : 'opacity-100'
              }`}
              onClick={navOpenHandler}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3 12H18'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3 6H21'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3 18H21'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
          </div>

          {/* Mobile nav */}
          <Transition
            show={navOpen}
            enter='transition-transform duration-200'
            enterFrom='-translate-x-[calc(100%+40px)]'
            enterTo='translate-x-0'
            leave='transition-transform duration-200'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-[calc(100%+40px)]'
            className='absolute top-0 left-0 z-10 h-screen duration-150 bg-white w-80 lg:hidden pb-11'
          >
            <div className='absolute top-0 left-0 z-10 h-screen p-4 bg-white w-80 lg:hidden pb-11'>
              <div className='flex flex-col h-full'>
                <Link to='/' onClick={navCloseHandler}>
                  <Logo />
                </Link>

                <div className='flex flex-col flex-1 gap-2 mt-12'>
                  {links.map((link, i) => (
                    <ScrollLink
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onClick={navCloseHandler}
                      className={`flex items-center gap-3 py-2 pl-2 rounded-full transition-colors`}
                      to={link.name}
                      key={i}
                    >
                      <span className='text-sm font-semibold text-[#20466F] font-raleway'>
                        {link.name}
                      </span>
                    </ScrollLink>
                  ))}
                </div>

                <div className='flex flex-col flex-1 p-2 mt-6 font-medium gap-y-3'>
                  <a
                    href='https://educarts-webapp.vercel.app/login'
                    target='_blank'
                    className='md:hidden inline-block text-[#17324F] text-[16px]'
                  >
                    <button>Log In</button>
                  </a>
                  <a
                    href='https://educarts-webapp.vercel.app/login'
                    target='_blank'
                    className='md:hidden inline-block border border-[#17324F] text-[16px] text-[#20466F] py-2 px-6 rounded-md'
                  >
                    <button>Sign Up</button>
                  </a>
                </div>
              </div>

              <button
                className='absolute top-4 -right-10'
                onClick={navCloseHandler}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18 6L6 18'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M6 6L18 18'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            </div>
          </Transition>

          <Transition
            show={navOpen}
            enter='transition-opacity duration-75'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className='absolute inset-0 h-screen bg-black/20 lg:hidden'
            onClick={navCloseHandler}
          ></Transition>
        </div>
      </div>
    </>
  );
}

export default Navbar;
