import React, { lazy } from 'react';
import PathConstants from './pathConstants';

const Home = lazy(() => import('../pages/home/Landing'));
const PaymentType = lazy(() => import('../pages/payment/PaymentType'));
const SevisFeeGetStarted = lazy(() =>
  import('../pages/payment/sevis-payments/GetStarted')
);
const SevisFeeForm = lazy(() =>
  import('../pages/payment/sevis-payments/SevisForm')
);
const SevisFeePaymentMethod = lazy(() =>
  import('../pages/payment/sevis-payments/SevisPayments')
);
const TrackPayment = lazy(() =>
  import('../pages/payment/track-payment/TrackPayments')
);
const Consultation = lazy(() => import('../pages/consultation/Consultation'));
const BookConsultation = lazy(() =>
  import('../pages/consultation/BookConsultation')
);

const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.PAYMENTTYPE, element: <PaymentType /> },
  { path: PathConstants.SEVISFEE, element: <SevisFeeGetStarted /> },
  { path: PathConstants.SEVISFEEFORM, element: <SevisFeeForm /> },
  {
    path: PathConstants.SEVISFEEPAYMENTMETHOD,
    element: <SevisFeePaymentMethod />,
  },
  { path: PathConstants.TRACKPAYMENT, element: <TrackPayment /> },
  { path: PathConstants.CONSULTATION, element: <Consultation /> },
  { path: PathConstants.BOOKCONSULTATION, element: <BookConsultation /> },
];

export default routes;
